/* 
 * VALOTA CONFIDENTIAL
 * __________________
 * 
 *  [2013] - [2018] Valota Limited 
 *  All Rights Reserved.
 * 
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 */

/* global _FLOW_HANDLER, FLOW_FLOW, ValotaTests */

ValotaTests = {
	testFlowFunction: function () {
		var error = [];
		if ($('iframe.active').length > 1) {
			error.push('more than one active in tick ' + _FLOW_HANDLER.totalTime);
		}
/*		if (!runFunction(_FLOW_HANDLER._CURRENT_CONTENT.getApp().container.contentWindow.ValotaIsHidden,'ValotaIsHidden', _FLOW_HANDLER._CURRENT_CONTENT.getApp().uuid)) {
			error.push('current not active ' + _FLOW_HANDLER.totalTime);
		}*/
		var n = 0;
		var active = "";
		for (var i = 0; i < _FLOW_HANDLER._CONTENTS.length; i++) {
			if (_FLOW_HANDLER._CONTENTS[i].type !== FLOW_FLOW) {
				if (typeof _FLOW_HANDLER._CONTENTS[i].getApp().container.contentWindow.ValotaIsHidden !== 'undefined') {
					if (!runFunction(_FLOW_HANDLER._CONTENTS[i].getApp().container.contentWindow.ValotaIsHidden,'ValotaIsHidden', _FLOW_HANDLER._CONTENTS[i].getApp().uuid)) {
						n++;
						active += _FLOW_HANDLER._CONTENTS[i].getApp().uuid;
					}
				}
			} else {
				n += ValotaTests.iterateCheck(_FLOW_HANDLER._CONTENTS[i].flow);
			}
		}
		if (n > 1) {
			error.push('more than one says active ' + _FLOW_HANDLER.totalTime + " " + active);
		}
		ValotaCustomTests.runTests(error);
		if (error.length > 0) {
			throw JSON.stringify(error);
		}
//		console.log("[Flow] tick", flow.totalTime, flow._name, $('iframe.active').length, n);
	}, // empty as will be replaced with
	iterateCheck: function (flow) {
		var n = 0;
		var active = "";
		for (var i = 0; i < flow._CONTENTS.length; i++) {
			if (flow._CONTENTS[i].type !== FLOW_FLOW) {
				if (typeof flow._CONTENTS[i].getApp().container.contentWindow.ValotaIsHidden !== 'undefined') {
					if (!runFunction(flow._CONTENTS[i].getApp().container.contentWindow.ValotaIsHidden,'ValotaIsHidden', flow._CONTENTS[i].getApp().uuid)) {
						n++;
						active += flow._CONTENTS[i].getApp().uuid;
					}
				}
			} else {
				n += ValotaTests.iterateCheck(flow._CONTENTS[i].flow);
			}
		}
		return n;
	},
	testsEnd: function () {
		// compare volumes with logs
		console.log("[Engine Tests] end");
		ValotaTests.checkVolumes(_FLOW_HANDLER);
	},
	checkVolumes: function (top_flow) {

		function iterateFlowForContentObject(flow, name, depth) {
			var retVal = [];
			for (var i = 0; i < flow._CONTENTS.length; ++i) {
				if (flow._CONTENTS[i].type !== FLOW_FLOW) {
					retVal.push({
						"Name": name + " - " + flow._CONTENTS[i].getShortName(),
						"Has Run": flow._CONTENTS[i].totalRun,
						"Volume": flow._CONTENTS[i].volume,
						"Current Vol": flow._CONTENTS[i].currentVolume,
						"Depth": depth,
						"Type": flow._CONTENTS[i].type
					});
				} else {
					var entries = iterateFlowForContentObject(flow._CONTENTS[i].flow, name + ' - ' + flow._CONTENTS[i].getShortName(), depth + 1);
					var index = retVal.length;
					retVal.push({
						"Name": name + " - " + flow._CONTENTS[i].getShortName(),
						"Has Run": 0,
						"Volume": flow._CONTENTS[i].volume,
						"Current Vol": 0,
						"Depth": depth,
						"Type": flow._CONTENTS[i].type
					});
					for (var c = 0; c < entries.length; c++) {
						if (entries[c]['Type'] !== FLOW_FLOW) {
							retVal[index]['Has Run'] += entries[c]['Has Run'];
						}
						retVal.push(entries[c]);
					}
				}
			}

			return retVal;
		}

		function calculateMaterializedWeights(table, depth, i2) {
			var curVol = 0;
			var vol = 0;
			var hasRun = 0;
			var i = i2;
			for (; i < table.length; i++) {
//				console.log(i);
				if (table[i]['Depth'] > depth) {
					i += calculateMaterializedWeights(table, table[i]['Depth'], i) - 1;
					continue;
				}
				if (table[i]['Depth'] < depth) {
					break;
				}
				curVol += table[i]["Current Vol"];
				vol += table[i]["Volume"];
				hasRun += table[i]["Has Run"];
			}
			var i3 = i2;
			var n = 0;
			for (; i3 < i; i3++) {
//				console.log("3", i3);
				if (table[i3]['Depth'] > depth) {
					n++;
					continue;
				}
				if (table[i3]['Depth'] < depth) {
					break;
				}
				table[i3]["Local Vol"] = table[i3]["Volume"] / vol;
				table[i3]["Cur Local Vol"] = (table[i3]["Has Run"] + table[i3]["Current Vol"] * table[i3]["Local Vol"]) / hasRun;
				n++;
			}
			return n;
		}

		function realWeights(table, runs, index, localVol) {
			var depth = table[index]['Depth'];
			var n = 0;
			for (var i = index; i < table.length; i++) {
				if (table[i]['Depth'] > depth) {
					var skip = realWeights(table, runs, i, table[i - 1]['Local Vol'] * localVol);
					i += skip;
					n += skip;
					continue;
				}
				if (table[i]['Depth'] < depth) {
					break;
				}
				n++;
				table[i]['Adj Vol'] = table[i]['Local Vol'] * localVol;
				table[i]['Real Vol'] = table[i]['Has Run'] / runs;
			}
			return n;
		}

		var ret = [];
		for (var i = 0; i < top_flow._CONTENTS.length; ++i) {
			if (top_flow._CONTENTS[i].type !== FLOW_FLOW) {
				ret.push({
					"Name": top_flow._CONTENTS[i].getShortName(),
					"Has Run": top_flow._CONTENTS[i].totalRun,
					"Volume": top_flow._CONTENTS[i].volume,
					"Current Vol": top_flow._CONTENTS[i].currentVolume,
					"Depth": 0,
					"Type": top_flow._CONTENTS[i].type
				});
			} else {
				var entries = iterateFlowForContentObject(top_flow._CONTENTS[i].flow, top_flow._CONTENTS[i].getShortName(), 1);
				var index = ret.length;
				ret.push({
					"Name": top_flow._CONTENTS[i].getShortName(),
					"Has Run": 0,
					"Volume": top_flow._CONTENTS[i].volume,
					"Current Vol": 0,
					"Depth": 0,
					"Type": top_flow._CONTENTS[i].type
				});

				for (var c = 0; c < entries.length; c++) {
					if (entries[c]['Type'] !== FLOW_FLOW) {
						ret[index]['Has Run'] += entries[c]['Has Run'];
					}
					ret.push(entries[c]);
				}
			}
		}

		calculateMaterializedWeights(ret, 0, 0);

		var totalRun = 0;
		var totalVol = 0;
		for (var i = 0; i < ret.length; i++) {
			if (ret[i]['Depth'] === 0) {
				totalRun += ret[i]['Has Run'];
				totalVol += ret[i]['Volume'];
			}
		}

		realWeights(ret, totalRun, 0, 1);
		console.table(ret);
		var sorted = [];
		for (var i = 0; i < ret.length; i++) {
			sorted.push({name: ret[i].Name, diff: (ret[i]['Adj Vol'] - ret[i]['Real Vol'])});
		}
		console.table(sorted);
//		console.table(realWeights);
	}

};

ValotaCustomTests = {runTests: function (errorArr) {}};