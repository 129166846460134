/* global FLOW_VOLUME_PINT, _VALOTA_TESTING_MAX, FLOW_SLOWNESS, FLOW_SLOWNESS_CYCLE, _applicationData */

/**
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 * Created by ukah on 21.11.2016.
 */


var FLOW_APP = 1;
var FLOW_FILL = 2;
var FLOW_FLOW = 3;





if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}
ValotaEngine.FlowContent = function (content, handler) {


	this.handler = handler;
	this.uuid = -1;
	this.volume = FLOW_VOLUME_PINT;
	this.schedule = false;

	this.volNow = 0;
	this.flow = null;
	this.hasRun = 0;
	this.totalRun = 0;
	this.logRun_ms = 0;
	this.started_ms = 0;
	//this.hourRun = 0;
	//this.dayRun = 0;
	this.cycleRun = 0;
	this.hasRunCycles = 0;
	this.type = FLOW_APP;
	this.app = null;
	this.status = 1;
	this.name = '';
	this.currentVolume = 0.0;
	this.latestContent = 0;
	this.always_follow = null;


	this.logPlay = function () {
		this.logRun_ms += Date.now() - this.started_ms;
		if (this.type === FLOW_FLOW) {
			this.flow.logPlay();

		}
	};

	this.logReset = function () {
		this.started_ms = Date.now();
		if (this.type === FLOW_FLOW) {
			this.flow.logReset();

		}
	};


	this.getName = function () {
		if (this.type === FLOW_APP) {
			return this.app ? this.app.name : this.name;
		}

		if (this.type === FLOW_FILL) {

			return this.name + ' ' + ((this.chosen && this.chosen.app) ? this.chosen.app.name : '');
			//return this.name + (this.chosen.name ? " - " + this.chosen.name : '');
		}

		if (this.type === FLOW_FLOW) {
			return this.flow.getName();
// TODO enable this with a display setting:			return this.flow._name + " - " + this.flow.getName();

		}
	};

	this.getShortName = function () {
		if (this.type === FLOW_APP) {
			return this.app ? this.app.name : this.name;
		}

		if (this.type === FLOW_FILL) {

			return this.name;
		}

		if (this.type === FLOW_FLOW) {

			return this.flow._name;

		}

	};

	this.setCycleRun = function (s) {
		this.cycleRun = s;

		if (this.type === FLOW_FLOW) {
			return this.flow.setCycleRun(s);
		}

	};

	this.runTime = function () {

		if (this.type === FLOW_APP) {
			if (typeof _VALOTA_TESTING_MAX !== 'undefined') {
				return Math.round(this.app.runTime);
			}
			return Math.round(this.app.runTime / FLOW_SLOWNESS);
		}

		if (this.type === FLOW_FILL) {
			if (typeof _VALOTA_TESTING_MAX !== 'undefined') {
				return Math.round(this.app.runTime);
			}
			return Math.round(this.chosen.app.runTime / FLOW_SLOWNESS);
		}

		if (this.type === FLOW_FLOW) {
			return this.flow.runTime();
		}

	};

	this.cycleTime = function () {
		if (this.type === FLOW_APP) {
			return Math.round(this.app.cycleTime / FLOW_SLOWNESS_CYCLE);
		}

		if (this.type === FLOW_FILL) {
			return Math.round(this.chosen.app.cycleTime / FLOW_SLOWNESS_CYCLE);
		}

		if (this.type === FLOW_FLOW) {
			return this.flow.cycleTime();
		}

	};

	this.getPreferredCycles = function () {

		if (this.type === FLOW_APP) {
			return this.app.preferredCycles;
		}

		if (this.type === FLOW_FILL) {
			return this.chosen.app.preferredCycles;
		}

		if (this.type === FLOW_FLOW) {
			return this.flow.getPreferredCycles();
		}

	};

	this.cycle = function (also_flow) {
		//Call app's cycle, reset cycle timer and increment cycles
		if (this.type !== FLOW_FLOW || (typeof also_flow !== 'undefined' && also_flow)) {
			runFunction(this.getApp().container.contentWindow.ValotaCycle,'ValotaCycle', this.getApp().uuid);
		}
		this.cycleRun = 0;
		++this.hasRunCycles;
	};

	this.getApp = function () {

		if (this.type === FLOW_APP) {
			return this.app;
		}

		if (this.type === FLOW_FILL) {

			if (!this.chosen) {
				this.chooseChosen();
			}

			if (this.chosen) {
				return this.chosen.app;
			}
			return null;
		}

		if (this.type === FLOW_FLOW) {
			return this.flow.getApp();
		}

	};

	this.removeVolume = function (val) {
		this.currentVolume -= parseFloat(val);
	};

	this.running = function () {
		++this.hasRun;
		++this.totalRun;
		//++this.logRun;
		//++this.hourRun;
		//++this.dayRun;
		++this.cycleRun;

	};


	/**
	 * OBS! If this logic is changed, similar exists in channel-server/www/api/valota_api/displays/get_display_details.php:getWeight and channel-client/dev/js/Flow.js:Flow.getCurrentWeight
	 * @param {type} d
	 * @returns {undefined}
	 */
	this.refreshVolume = function (d) {

		if (typeof this.schedule !== 'object') {
			this.volNow = parseFloat(this.volume);
			return;
		}
		if (typeof d === 'undefined') {
			d = new Date();
		}


		// loop through volumes
		var tempVolume = false;
		for (var i = 0; i < this.schedule.length; ++i) {
			var v = this.schedule[i];
			if (isset(v.days) || isset(v.times)) {
				var timeCheck = true;
				// time fork
				if (isset(v.times)) {
					var timeCheck = false;

					var times, secs, comp;
					for (var j = 0; j < v.times.length; ++j) {
						var y = v.times[j];
						if (isset(y.start)) {

							//is current time more then start time
							times = y.start.split(':');
							secs = timeToSecs(times[0], times[1], times[2]);
							comp = timeToSecs(d.getHours(), d.getMinutes(), d.getSeconds());

							if (comp < secs) {
								// if not continue to next condition
								continue;
							}
						}

						if (isset(y.end)) {

							//is current time less then end time
							times = y.end.split(':');
							secs = timeToSecs(times[0], times[1], times[2]);
							comp = timeToSecs(d.getHours(), d.getMinutes(), d.getSeconds());
							if (comp > secs) {
								// if not continue to next condition
								continue;
							}

						}
						timeCheck = true;

						break;
					}
					if (!timeCheck) {
						continue;
					}


				}



				// do we have valid days ?
				if (isset(v.days) && v.days.length) {
					// is current day one of valid days
					if (v.days.indexOf(d.getDay() + '') === -1) {
						//if it is not then continue to next condition
						continue;
					}
				}

				//times and days checked so save tempVolume if it is more then current
				// temp volume
				if (tempVolume === false || this.weightIsLower(tempVolume, v.volume)) {
					tempVolume = parseFloat(v.volume);

				}
				continue;
			}

			// do we have time stamp_stamps?
			if (isset(v.startStamp) || isset(v.endStamp)) {
				var comp;
				if (isset(v.startStamp)) {
					//do we have start stamp?
					comp = new Date(v.startStamp).getTime();
					if (d.getTime() < comp) {
						continue;
					}

				}

				if (isset(v.endStamp)) {
					comp = new Date(v.endStamp).getTime();
					if (d.getTime() > comp) {
						continue;
					}
				}
				//dates checked so save tempVolume if it is more then current
				if (tempVolume === false || this.weightIsLower(tempVolume, v.volume)) {
					tempVolume = parseFloat(v.volume);

				}


			}

			if (tempVolume === false) {
				tempVolume = parseFloat(v.volume);
				continue;
			}

		}

		this.volNow = tempVolume ? tempVolume : parseFloat(this.volume);


	};

	this.weightIsLower = function (a, b) {
		if (a === b) {
			return false;
		}
		if (b === -10) {
			return true;
		}
		if (a === -10)
			return false;
		if (b === -1) {
			return true;
		}

		return a < b;
	};

	this.updateContent = function (new_content) {
		if (this.type !== new_content.type) {
			console.warn('[Engine FlowContent] Different type content with the same UUID!');
		}
		// new volume
		this.volume = new_content.volume;

		// new name
		//this.name = new_content.name;

		if (new_content.latestContent > this.latestContent) {
			this.latestContent = new_content.latestContent;
		}

		/*
		 if (isset(new_content.runTime)) {
		 this.runTime = new_content.runTime;
		 }
		 
		 if (isset(new_content.cycleTime)) {
		 this.cycleTime = new_content.cycleTime;
		 }*/

		if (isset(new_content.always_follow)) {
			this.always_follow = new_content.always_follow;
		}



		if (isset(new_content.schedule)) {
			this.schedule = new_content.schedule;
		} else {
			this.schedule = false;
		}

		if (this.type === FLOW_FILL) {

			// flow fill
			this.apps = new_content.apps;
			this.chooseChosen();

			return;
		}

		if (this.type === FLOW_FLOW && this.flow._updateId !== new_content.flow.config.updateId) {
			//flow flow
			this.flow._updateId = new_content.flow.config.updateId;
			this.flow._name = new_content.flow.config.name;
			this.flow.loadContents(new_content.flow.contents);
			return;
		}
	};

	this.newContentOn = function (uuid, time) {

		var el = this.getFillApp(uuid);

		if (el === false) {
			return;
		}

		el.latestContent = time;

		this.chooseChosen();

	};

	this.getFillApp = function (uuid) {
		if (this.type === FLOW_FILL) {
			for (var i in this.apps) {
				if (this.apps[i].uuid === uuid || this.apps[i] === uuid) {
					return this.apps[i];
				}
			}
		}
		return false;
	};

	this.chooseChosen = function () {

		if (this.type === FLOW_FILL && this.apps.length) {

			var latest = -90000;
			for (var num in this.apps) {
				if (isset(this.apps[num].app) &&
						this.apps[num].app.ready &&
						this.apps[num].app.latestContent > latest) {

					latest = this.apps[num].latestContent;
					this.latestContent = this.apps[num].latestContent;
					this.chosen = this.apps[num];
				}
			}
		} else {
			this.chosen = null;
		}

	};


	if (isset(content)) {
		/*if (isset(content.name)) {
		 this.name = content.name;
		 }
		 
		 if (isset(content.runTime)) {
		 this.runTime = content.runTime;
		 }
		 
		 if (isset(content.cycleTime)) {
		 this.cycleTime = content.cycleTime;
		 }
		 */

		if (isset(content.uuid)) {
			this.uuid = content.uuid;
		}

		if (isset(content.volume)) {
			this.volume = content.volume;
		}

		if (isset(content.schedule)) {
			this.schedule = content.schedule;
		}

		if (isset(content.type)) {
			this.type = content.type;
		}

		if (isset(content.name)) {
			this.name = content.name + ' - ';
		}

		if (isset(content.latestContent)) {
			this.latestContent = content.latestContent;
		}

		if (isset(content.always_follow)) {
			this.always_follow = content.always_follow;
		}

		if (this.type === FLOW_FILL) {
			this.apps = [];
			if (isset(content.apps)) {
				// app list
				this.apps = content.apps;

				for (var i in this.apps) {

					var st = getStoryLoc(this.apps[i].uuid);

					if (st !== -1) {
						this.apps[i].app = _applicationData[st];
					}


				}

				this.chosen = null;
				if (isset(content.initial_chosen)) {
					var it = this.getFillApp(content.initial_chosen);
					if (it) {
						this.chosen = it;
					}
				}
				if (!this.chosen) {
					this.chooseChosen();
				}
			}
		}
		if (this.type === FLOW_FLOW && isset(content.flow)) {
			this.flow = new ValotaEngine.FlowHandler(content.flow.config, this.handler._level+1);
			this.flow.loadContents(content.flow.contents);
		}
	}
	if (this.type === FLOW_APP && this.uuid) {
		var num = getStoryLoc(this.uuid);

		if (num !== -1) {
			this.app = _applicationData[num];
			this.name += this.app.name;
		}


	}

	this.refreshVolume();
	this.currentVolume = this.volNow;

};