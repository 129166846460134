/* global ValotaEngine, _applicationData, _VALOTA_REST, currentStory, runPendingFunctions, _VALOTA_DEVICE */

/*
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2018] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 * 
 * Created on 20.7.2018 13:47
 *
 * @author ukah
 * 
 * 
 */


function handleDisplayData(re, websocket) {
	// check emergency message
	if (!websocket || typeof re.emergency_message !== "undefined") {
		if (typeof re.emergency_message === "string" && re.emergency_message !== "") {
			setLocal('emergency', re.emergency_message);
		} else {
			setLocal('emergency', null);
		}
		checkEmergency();
	}
	if (typeof (re.node) !== 'undefined') {
		// We should connect to a node
		if (!re.node) {
			ValotaEngine.WebSocket.url = null;
			setLocal('node_servers', null);
			ValotaEngine.WebSocket.setNewNodeList([]);
			ValotaEngine.WebSocket.close();
			if (_REQUEST_TIMER === null) {
				fetchData();
			}
			_WEBSOCKET = false;
			_NODE_SERVERS = [];
		} else {
			try {
				ValotaEngine.WebSocket.setNewNodeList(re.node);
				ValotaEngine.WebSocket.reconnect();
				_WEBSOCKET = true;
				_NODE_SERVERS = re.node;
			} catch (e) {
				console.error('[Engine CB] Unable to set new node list ' + e);
				setLocal('node_servers', null);
				ValotaEngine.WebSocket.setNewNodeList([]);
				ValotaEngine.WebSocket.close();
				if (_REQUEST_TIMER === null) {
					fetchData();
				}
				_WEBSOCKET = false;
				_NODE_SERVERS = [];
			}
		}
	}


	//we are expecting an array from websocket and string from fetch
	let doReturn = false;
	let doReload = 0;
	let doReloadApp = false;

	if (typeof (re.action) !== "undefined" || typeof (re.actions) !== "undefined") {
		let acts =[];

		if(typeof (re.action) !== "undefined") {
			if (typeof (re.action) === 'string') {
				acts.push(re.action);
			} else if (Array.isArray(re.action)) {
				acts = re.action;
			}
		}
		if(typeof (re.actions) !== "undefined") {
			if (typeof (re.actions) === 'string') {
				acts.push(re.actions);
			} else if (Array.isArray(re.actions)) {
				acts = acts.concat(re.actions);
			}
		}

		acts = acts.reduce((a,b) => {
			if (a.indexOf(b) === -1) a.push(b);
			return a;
		},[]);



		for (let i = 0; i < acts.length; ++i) {

			// we have an unclaimed display
			if (acts[i] === 'new_display') {

				ValotaEngine.ClaimDisplay.identifier = re.identifier;

				ValotaEngine.ClaimDisplay.show();

				localStorage.clear();

				doReturn = true;
			}

			if (acts[i] === 'reload_app') {
				doReloadApp = true;
				doReturn = true;
			}


			if (acts[i] === 'reload') {
				if (doReload === 0) {
					doReload = 1;
				}
				doReturn = true;
			}

			if (acts[i] === 'new_display_uuid') {
				console.warn("[Engine FetchCB] new uuid");
				_displayID = re.new_display_uuid;

				setLocal('display', _displayID);
				_DISPLAY_UPDATED = null;
				saveUUID();
				doReload = 2;
				doReturn = true;

			}

			if (acts[i] === 'hard_reload') {
				for (let j = 0; j < _applicationData.length; ++j) {
					if (!_applicationData[j].container) {
						continue;
					}
					try {
						_applicationData[j].container.contentWindow.location.reload(true);
					} catch (e) {
						console.error('reloading ' + _applicationData[j].uuid + "failed", e);
					}
				}

				doReload = 2;
				doReturn = true;

			}


			if (acts[i] === 'deleted') {
				_applicationData = null;
				_displayID = null;
				_overlayApp = null;
				_displayDate = null;
				_displayName = null;
				_displayUser = null;
				_viewDistance = null;
				_ownerLogo = null;
				_STATIC_SERVE = null;
				_SERVED_APP = null;
				localStorage.clear();
				clearUUID();
				init();
				doReload = 2;

				doReturn = true;
			}
			if (acts[i] === 'timesync') {
				let nowTime = Date.now();
				console.log("[Engine] time check",_TIME_CHECK, re.node_time, ValotaEngine.WebSocket.timesyncSent, Date.now());
				if (Math.abs((nowTime + ValotaEngine.WebSocket.timesyncSent) / 2 - re.node_time) > 5000) {
					console.error("[Engine]", "Time difference more than 5 seconds, adjusting");
					_TIME_CHECK = re.node_time - (nowTime + ValotaEngine.WebSocket.timesyncSent) / 2;
					setClocks();
				}
			}
			// TODO:do more actions??
		}
	}

	if (typeof (re.support_action) !== "undefined") {
		for (let key = 0; key < re.support_action.length; ++key) {
			if (re.support_action[key] === 'log_db') {
				var request = {
					action: "valota_api/log/log_db",
					appUUID: "DB dump",
					displayUUID: _displayID,
					message: JSON.stringify(localStorage)
				};
				$.ajax({
					type: "POST",
					url: _VALOTA_REST,
					data: request,
					dataType: "json"
				}).done(function () {

				}).fail(function (xhr, st, err) {
					var mes = 'Failed to send log: ' + err + ' (' + st + ')';
					console.error("[FetchCB] " + mes);
				});
			}

			if (re.support_action[key] === 'send_screenshot') {
				setTimeout(takeScreenshot, 1000);
			}
		}
	}

	if (doReloadApp) {
		reloadApp();
	}

	if (doReload === 1) {
		reloadDisplay(false);
	}

	if (doReload === 2) {
		window.setTimeout(function () {
			localStorage.clear();
			reloadDisplay(true);
		}, 1000); // 1 second
	}

	if (doReturn) {
		return false;
	}

	if (typeof re.display_status !== "undefined") {
		switch (re.display_status) {
			case 'off':
				displayOff();
				break;
			case 'on':
				displayOn();
				break;
			default:
				console.warn('[FetchDataCB] unknown display status ' + re.display_status);
				break;
		}
	} else {
		if (!websocket) {
			displayOn();
		}
	}

	if (typeof re.unpaid !== "undefined") {
		if (re.unpaid) {
			unpaid();
		} else {
			paid();
		}
	} else {
		if (!websocket) {
			paid();
		}
	}

	if (typeof (re.display_name) !== "undefined") {
		setDisplayName(re.display_name);
	}

	if (typeof (re.display_user) !== "undefined") {
		setDisplayUser(re.display_user);
	}

	if (typeof (re.owner_logo) !== "undefined") {
		setOwnerLogo(re.owner_logo);
	}

	if (typeof (re.create_time) !== "undefined") {
		setClaimDate(re.create_time);
	}

	if (typeof (re.display_updated) !== "undefined") {
		_DISPLAY_UPDATED = re.display_updated;
		setLocal('display_updated', _DISPLAY_UPDATED);
	}

	let render = false;

	if (typeof (re.show_stats) !== "undefined") {
		_SHOW_STATS = re.show_stats ? true : false;
		setLocal('show_stats', _SHOW_STATS);
		render = true;
	}

	if (typeof (re.overlay_conf) !== "undefined") {
		if (re.overlay_conf !== null && re.overlay_conf.hasOwnProperty('uuid')) {
			if (_overlayApp && _overlayApp !== re.overlay_conf.uuid) {
				var overlayNum = getStoryLoc(_overlayApp);
				if (overlayNum >= 0) {
					stopOverlay(overlayNum);
					_applicationData.splice(overlayNum, 1);
				}
				document.getElementById('overlay').innerHTML = "";
				document.getElementById('overlay').style.display = "none";
			}
			_overlayApp = re.overlay_conf.uuid;
		} else {
			if (_overlayApp) {
				var overlayNum = getStoryLoc(_overlayApp);
				if (overlayNum >= 0) {
					stopOverlay(overlayNum);
					_applicationData.splice(overlayNum, 1);
				}
				document.getElementById('overlay').innerHTML = "";
				document.getElementById('overlay').style.display = "none";
			}
			_overlayApp = null;
		}
		setLocal('overlay_app', _overlayApp);
	}

	if (typeof (re.show_title) !== "undefined") {
		_SHOW_TITLE = re.show_title ? true : false;
		setLocal('show_title', _SHOW_TITLE);
		render = true;
	}
	if (typeof (re.show_logo) !== "undefined") {
		_SHOW_LOGO = re.show_logo ? true : false;
		setLocal('show_logo', _SHOW_LOGO);
		render = true;
	}
	if (typeof (re.show_next) !== "undefined") {
		_SHOW_NEXT = re.show_next ? true : false;
		setLocal('show_next', _SHOW_NEXT);
		render = true;
	}

	if (typeof (re.show_title_clock) !== "undefined") {
		_SHOW_TITLE_CLOCK = re.show_title_clock ? JSON.parse(re.show_title_clock) : false;
		setLocal('show_title_clock', _SHOW_TITLE_CLOCK);
		render = true;
	}

	if (typeof (re.show_stats_clock) !== "undefined") {
		_SHOW_STATS_CLOCK = re.show_stats_clock ? JSON.parse(re.show_stats_clock) : false;
		setLocal('show_stats_clock', _SHOW_STATS_CLOCK);
		render = true;
	}

	if (typeof (re.time_check_epoch) !== "undefined" && !_WEBSOCKET) {
		var nowTime = Date.now();

		_TIME_CHECK = nowTime - (parseInt(re.time_check_epoch) * 1000);

	}

	let paletteChanged = false;
	if (typeof (re.view_distance) !== "undefined") {
		if (re.view_distance !== _viewDistance) {
			_viewDistance = re.view_distance;
			setLocal('view_distance', _viewDistance);
			paletteChanged = true;
		}


	}
	if (render) {
		engineLayout();
	}

	let redoLocalFlow = false;
	let justLoaded = [];
	let changesPending = false;
	if (typeof (re.apps) !== "undefined") {


		for (let i = 0; i < re.apps.length; ++i) {

			let app = re.apps[i];

			if (!app.uuid) {
				continue;
			}

			let loc = getStoryLoc(app.uuid);


			// different app that we have here so lets add it to the list
			if (loc === -1) {
				if (typeof (app.deleted) === 'undefined' || !app.deleted) {
					redoLocalFlow = true;
					_applicationData.push(app);

					justLoaded.push(_applicationData.length - 1);
					loadApplication(_applicationData.length - 1);
				}

			} else {

				if (typeof (app.deleted) !== 'undefined' && app.deleted) {
					redoLocalFlow = true;
					unloadApplication(loc);
				} else {
					// old app, lets check different values
					// Source has changed
					if (typeof (app.source) !== "undefined") {


						// do we just have source delay??
						if (typeof (app.source.delayed) !== "undefined" && app.source.delayed) {
							if (typeof _applicationData[loc].source.delayed === 'undefined' || _applicationData[loc].source.delayed === null) {
								_applicationData[loc].source.delayed = new Date();
							}
						} else {
							// or has the source changed
							_applicationData[loc].source = app.source;
							if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[loc].uuid === _overlayApp) {
								ValotaEngine.ChromeOS.overlayAppChangedSource(_applicationData[loc].source);
							} else {
								if (_applicationData[loc].ready) {
									runFunction(_applicationData[loc].container.contentWindow.ValotaSourceDataHasChanged, 'ValotaSourceDataHasChanged', _applicationData[loc].uuid);
								} else {
									if (_applicationData[loc].changesPending.indexOf('ValotaSourceDataHasChanged') === -1) {
										_applicationData[loc].changesPending.push('ValotaSourceDataHasChanged');
									}
								}
							}
						}


					}

					// palette
					if (typeof (app.palette) !== "undefined") {
						_applicationData[loc].palette = app.palette;
						preloadBgs(loc);
						if (loc === currentStory) {
							loadBg(loc);
						}
						if (!paletteChanged) {
							if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[loc].uuid === _overlayApp) {
								ValotaEngine.ChromeOS.overlayAppChangedPalette(_applicationData[loc].palette);
							} else {
								if (_applicationData[loc].ready) {
									runFunction(_applicationData[loc].container.contentWindow.ValotaPaletteHasChanged, 'ValotaPaletteHasChanged', _applicationData[loc].uuid);
								} else {
									if (_applicationData[loc].changesPending.indexOf('ValotaPaletteHasChanged') === -1) {
										_applicationData[loc].changesPending.push('ValotaPaletteHasChanged');
									}
								}
							}
						}
					}

					// customs
					if (typeof (app.customs) !== "undefined") {
						_applicationData[loc].customs = app.customs;
						if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[loc].uuid === _overlayApp) {
							ValotaEngine.ChromeOS.overlayAppChangedCustoms(_applicationData[loc].customs);
						} else {
							if (_applicationData[loc].ready) {
								runFunction(_applicationData[loc].container.contentWindow.ValotaCustomsHaveChanged, 'ValotaCustomsHaveChanged', _applicationData[loc].uuid);
							} else {
								if (_applicationData[loc].changesPending.indexOf('ValotaCustomsHaveChanged') === -1) {
									_applicationData[loc].changesPending.push('ValotaCustomsHaveChanged');
								}
							}
						}

					}

					// name
					if (isset(app.name)) {
						_applicationData[loc].name = app.name;
					}

					if (isset(app.index_time)) {
						_applicationData[loc].index_time = app.index_time;
					}

					//There are changes pending
					if (!changesPending && _applicationData[loc].changesPending.length > 0) {
						changesPending = true;
					}

				}

			}


		}

		setLocal('application_data', _applicationData);


	}
	if (paletteChanged) {
		for (let i in _applicationData) {
			if (justLoaded.indexOf(i) !== -1) {
				continue;
			}
			if (typeof _VALOTA_DEVICE !== 'undefined' && _applicationData[i].uuid === _overlayApp) {
				ValotaEngine.ChromeOS.overlayAppChangedPalette(_applicationData[i].palette);
			} else {
				preloadBgs(i);

				if (_applicationData[i].ready) {
					runFunction(_applicationData[i].container.contentWindow.ValotaPaletteHasChanged, 'ValotaPaletteHasChanged', _applicationData[i].uuid);
				} else {
					if (_applicationData[i].changesPending.indexOf('ValotaPaletteHasChanged') === -1) {
						_applicationData[i].changesPending.push('ValotaPaletteHasChanged');
					}
				}
				if (!changesPending && _applicationData[i].changesPending.length > 0) {
					changesPending = true;
				}
			}

		}
	}
	if (changesPending) {
		setTimeout(function () {
			runPendingFunctions(1);
		}, 100); // run pending functions after .1 secs
	}

	if (typeof (re.flow) !== "undefined") {
		if (typeof (re.flow.deleted) !== "undefined" && re.flow.deleted) {
			// flow has been deleted
			if (_FLOW_HANDLER) {
				saveLog();

			}

			_FLOW_HANDLER = null;
			_flow = null;
			setLocal('flow', null);
			runStories();
		} else {
			//update or insert flow
			_flow = re.flow;
			setLocal('flow', _flow);
			reFlow();
		}

	} else if (redoLocalFlow) {
		// see if we have an app
		_flow = null;
		setLocal('flow', null);
		runStories();
	}
	return true;
}