/* global FLOW_FLOW, FLOW_FILL, _FLOW_HANDLER, _VALOTA_TESTING_MAX, ValotaTests, _FLOW_TIMEOUT */

/**
 * VALOTA CONFIDENTIAL
 * __________________
 *
 * [2013] - [2016] Valota Limited
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the
 * property of Valota Limited and its suppliers, if any. The
 * intellectual and technical concepts contained herein are
 * proprietary to Valota Limited and its suppliers and may be covered
 * by Finnish and Foreign Patents, patents in process, and are
 * protected by trade secret or copyright law. Dissemination of this
 * information or reproduction of this material is strictly forbidden
 * unless prior written permission is obtained from Valota Limited.
 *
 *
 * Created by ukah on 21.11.2016.
 */



var FLOW_VOLUME_MUST_SHOW = -10; // app/flow is forcefully shown while this is on
var FLOW_VOLUME_OFF = -1; // app/flow is off
var FLOW_VOLUME_NIL = 0;
var FLOW_VOLUME_CUP = 0.25;
var FLOW_VOLUME_CAN = 0.5;
var FLOW_VOLUME_PINT = 1;
var FLOW_VOLUME_BOTTLE = 2;
var FLOW_VOLUME_GALLON = 4;
var FLOW_VOLUME_BARREL = 8;

var FLOW_PAUSED = false;
var FLOW_STATUS_ON = 1;
var FLOW_STATUS_OFF = 0;

var FLOW_SLOWNESS = 1; // Flow is recalculated every FLOW_SLOWNESS seconds,
var FLOW_SLOWNESS_CYCLE = 1; // Flow is recalculated every FLOW_SLOWNESS seconds,

if (typeof ValotaEngine === 'undefined') {
	var ValotaEngine = {};
}

ValotaEngine.FlowHandler = function (input, level) {
	console.log('[FLOW] generate new FlowHandler');
	this._name = 'Untitled'; // name of the flow
	this._updateId = null;
	this._uuid = null;
	this._level = level;
	this.logStart = Math.round(Date.now() / 1000);

	for (var e in input) {
		switch (e) {
			case 'name':
				this._name = input[e]; // name of the flow
				break;

			case 'updateId':
				this._updateId = input[e]; // update time of the flow
				break;

			case 'uuid':
				this._uuid = input[e]; // uuid of the flow
				break;

		}


	}


	this._CONTENTS = []; // all contents of the flow
	this._PARENT_FLOW = null;
	this._CURRENT_CONTENT = null; // current fill content
	this._NEXT_CONTENT = null; // next content
	this._this = this;

	this.latestContent = 0;

	this.totalTime = 0;
	//this.dayTime = 0;
	//this.hourTime = 0;

	this.addContent = function (content) {
		if (content.latestContent > this.latestContent) {
			this.latestContent = content.latestContent;
		}
		var cont = this.getContent(content.uuid);

		if (!cont) {
			var newCont = new ValotaEngine.FlowContent(content, this);

			// add to flow
			this._CONTENTS.push(newCont);
			// if this was first make it the default
			if (newCont.type === FLOW_FLOW) {
				newCont.flow._PARENT_FLOW = this;
			}

		} else {
			// exists
			cont.updateContent(content);

		}


	};

	this.newContentOnContent = function (uuid, time) {
		if (time > this.latestContent) {
			this.latestContent = time;
		}

		for (var i in this._CONTENTS) {
			if (this._CONTENTS[i].uuid === uuid) {
				this._CONTENTS[i].latestContent = time;
			}
			if (this._CONTENTS[i].type === FLOW_FILL) {
				this._CONTENTS[i].newContentOn(uuid, time);
			}

			if (this._CONTENTS[i].type === FLOW_FLOW) {
				this._CONTENTS[i].flow.newContentOnContent(uuid, time);
			}
		}
	};

	this.addTick = function () {
		++this.totalTime;
		if (this._uuid === _FLOW_HANDLER._uuid && typeof _VALOTA_TESTING_MAX !== 'undefined') {
//			console.log("[Flow] tick", this.totalTime, this._name);

			ValotaTests.testFlowFunction();

			if (this.totalTime > _VALOTA_TESTING_MAX) {
				window.clearInterval(_FLOW_TIMEOUT);
				ValotaTests.testsEnd();
				throw "[Flow Test] end";
			}
		}
		//++this.dayTime;
		//++this.hourTime;
	};


	this.getContent = function (uuid) {

		for (var i = 0; i < this._CONTENTS.length; ++i) {
			if (this._CONTENTS[i].uuid === uuid) {

				return this._CONTENTS[i];
			}
		}

		return false;
	};

	this.getName = function () {
		if (this._CURRENT_CONTENT) {
			return this._CURRENT_CONTENT.getName();
		}
	};

	this.runTime = function () {
		if (this._CURRENT_CONTENT) {
			return this._CURRENT_CONTENT.runTime();
		}
	};

	this.cycleTime = function () {
		if (this._CURRENT_CONTENT) {
			return this._CURRENT_CONTENT.cycleTime();
		}
	};

	this.setCycleRun = function (s) {
		if (this._CURRENT_CONTENT) {
			return this._CURRENT_CONTENT.setCycleRun(s);
		}
	};

	this.getPreferredCycles = function () {
		if (this._CURRENT_CONTENT) {
			return this._CURRENT_CONTENT.getPreferredCycles();
		}
	};

	this.logPlay = function () {
		if (this._CURRENT_CONTENT) {
			this._CURRENT_CONTENT.logPlay();
		}

	};

	this.logReset = function () {
		if (this._CURRENT_CONTENT) {
			this._CURRENT_CONTENT.logReset();
		}
	};


	this.getApp = function () {
		if (!this._CURRENT_CONTENT) {
			this.chooseContent(false);
		}
		if (this._CURRENT_CONTENT) {
			return this._CURRENT_CONTENT.getApp();
		}

	};

	// choose the best app
	this.getTopChoices = function () {
		var orderApps = [];
		var mustShow = [];
		var alwaysFollow = [];

		for (var i = 0; i < this._CONTENTS.length; ++i) {

			//App is not set or is not ready
			if (!this._CONTENTS[i].getApp() || !this._CONTENTS[i].getApp().ready) {
				continue;
			}

			if (this._CONTENTS[i].always_follow && this._CURRENT_CONTENT && this._CURRENT_CONTENT.uuid === this._CONTENTS[i].always_follow) {
				alwaysFollow.push(this._CONTENTS[i]);
				break;
			}

			// Content is off
			if (this._CONTENTS[i].status === FLOW_STATUS_OFF) {
				continue;
			}



			if (this._CONTENTS[i].volNow === FLOW_VOLUME_MUST_SHOW) {
				mustShow.push(this._CONTENTS[i]);
				continue;
			}
			var place = -1;
			for (var j = 0; j < orderApps.length; ++j) {
				if (this._CONTENTS[i].currentVolume > orderApps[j].currentVolume) {
					place = j;
					break;
				}
			}

			//None is bigger
			if (place === -1) {
				orderApps.push(this._CONTENTS[i]);
			} else {
				orderApps.splice(place, 0, this._CONTENTS[i]);

			}
		}

		//console.log("[FLOW]", orderApps);
		if (alwaysFollow.length) {
			return alwaysFollow;
		} else if (mustShow.length) {
			return mustShow;
		} else {
			var now = 0;
			var size = orderApps.length;

			if (!size || size === 1) {
				return orderApps;
			}

			now = Math.floor(Math.log2(size));

			var cutPoints = [];
			for (var i = 0; i < size - 1; ++i) {
				var dist = orderApps[i].currentVolume - orderApps[i + 1].currentVolume;

				var loc = -1;
				for (var j = 0; j < cutPoints.length; ++j) {
					if (cutPoints[j].distance < dist) {
						loc = j;
						break;
					}
				}
				if (loc === -1) {
					cutPoints.push({
						point: i,
						distance: dist
					});
				} else {
					cutPoints.splice(loc, 0, {
						point: i,
						distance: dist
					}
					);
				}
			}

			var punkt = -1;
			for (var i = 0; i < now; ++i) {
				if (punkt === -1 || cutPoints[i].point < punkt) {
					punkt = cutPoints[i].point;
				}
			}
			return orderApps.slice(0, punkt + 1);
		}
	};
	this.staysOnSelf = function () {
		if (this._NEXT_CONTENT === null) {
			console.warn('[FLOW] No app to change to');
			return true;
		}

		return this._CURRENT_CONTENT.getApp().uuid === this._NEXT_CONTENT.getApp().uuid;

	};

	this.changeToContent = function (hideOnly) {
		var cont;
		if (this._NEXT_CONTENT !== null) {
			cont = this._NEXT_CONTENT;
		} else {
			console.warn('[FLOW] ' + this._name + ' ' + this.totalTime + ' No app to change to');
		}


		console.log("[FLOW]", this._name, this.totalTime, " last run", this._CURRENT_CONTENT.hasRun, this._CURRENT_CONTENT.cycleRun);
		cont.hasRun = 0;
		cont.cycleRun = 0;
		cont.hasRunCycles = 0;
		//console.log("[FLOW] \n" + this.getContentString());
		if (this._NEXT_CONTENT === this._CURRENT_CONTENT) {
			if (hideOnly) {
				console.log('[FLOW] Not cycling ' + this._CURRENT_CONTENT.getName() + " because parent flow is switching this tick.");
			} else {
				console.log('[FLOW] continue playing ' + this._CURRENT_CONTENT.getName());
				console.log('[FLOW] ' + this._name + ' ' + this.totalTime + ' Cycle called from changeToContent() when same content');
				cont.hasRunCycles = -1;
				this._CURRENT_CONTENT.cycle();
			}
		} else {
			console.log('[FLOW] ' + this._name + ' ' + this.totalTime + ' From ' + this._CURRENT_CONTENT.getName() + ' to ' + this._NEXT_CONTENT.getName());
			var old_uuid = this._CURRENT_CONTENT.getApp().uuid;
			this._CURRENT_CONTENT.logPlay();
			this._NEXT_CONTENT.logReset();

			this._CURRENT_CONTENT = this._NEXT_CONTENT;
			var new_uuid = this._CURRENT_CONTENT.getApp().uuid;

			if (old_uuid !== new_uuid) {
				if (!hideOnly) {
					var st = getStoryLoc(this._CURRENT_CONTENT.getApp().uuid);
					playStory(st);
				} else {
					console.log("[FLOW] Not switching to " + this._CURRENT_CONTENT.getName() + " because parent flow is also switching this tick.");
				}


			} else {
				console.log('[FLOW]' + this._name + ' ' + this.totalTime + 'Cycle called from changeToContent() when uuids match');
				cont.hasRunCycles = -1;
				this._CURRENT_CONTENT.cycle(true);
			}

		}

		console.table(this.getContentObject());

		this._NEXT_CONTENT = null;


	};

	this.refreshCurrentVolumes = function () {
		var date = new Date();
		for (var i = 0; i < this._CONTENTS.length; ++i) {
			this._CONTENTS[i].refreshVolume(date);
			//this._CONTENTS[i].refreshVolume(_DATE);//This was for logging
		}
	};

	this.tick = function (hide_only, no_pause) {
		if (typeof hide_only === 'undefined') {
			hide_only = false;
		}
		if (typeof no_pause === 'undefined') {
			no_pause = false
		}

		if (FLOW_PAUSED && !no_pause) {
			return;
		}

		if (this._CURRENT_CONTENT && this._CURRENT_CONTENT.getApp() && this._CURRENT_CONTENT.getApp().ready === false) {
			this._CURRENT_CONTENT = null;
		}

		if (this._CURRENT_CONTENT === null) {
			this.chooseContent(true);
			if (this._CURRENT_CONTENT === null) {
				showWindow('waiting_for_content');
				var t = this;
				setTimeout(function () {
					t.tick.call(t, hide_only, no_pause);
				}, 300);
				return;
			} else {
				showWindow('valota_container');
			}
		}
		this.addTick();
		var tot_vol = 0;


		this.refreshCurrentVolumes();

		var anotherMust = false;

		for (var i = 0; i < this._CONTENTS.length; ++i) {
			if (this._CONTENTS[i] === this._CURRENT_CONTENT) {
				continue;
			}

			if (this._CONTENTS[i].volNow === FLOW_VOLUME_OFF) {
				this._CONTENTS[i].status = FLOW_STATUS_OFF;
			} else {
				this._CONTENTS[i].status = FLOW_STATUS_ON;
				if (this._CURRENT_CONTENT.volNow === FLOW_VOLUME_MUST_SHOW) {
					if (this._CONTENTS[i].currentVolume === FLOW_VOLUME_MUST_SHOW) {
						anotherMust = true;
						if (this._CONTENTS[i].getApp() && this._CONTENTS[i].getApp().ready === true) {
							this._CONTENTS[i].currentVolume += this._CONTENTS[i].volNow;
							tot_vol += this._CONTENTS[i].volNow;
						}

					}


				} else {
					if (this._CONTENTS[i].getApp() && this._CONTENTS[i].getApp().ready === true) {
						this._CONTENTS[i].currentVolume += this._CONTENTS[i].volNow;
						tot_vol += this._CONTENTS[i].volNow;
					}
				}

			}
		}
		//tot_vol += this._CURRENT_CONTENT.volNow;
		if (this._CURRENT_CONTENT.volNow === FLOW_VOLUME_MUST_SHOW) {
			if (anotherMust) {
				this._CURRENT_CONTENT.removeVolume(tot_vol);
			}
		} else {
			this._CURRENT_CONTENT.removeVolume(tot_vol);
		}
		var topChoices = this.getTopChoices();

		var chooseNext = false;
		var curCycleTime = this._CURRENT_CONTENT.cycleTime();

		// choose next content if content has run more then half of its preferred cycles
		if (this._NEXT_CONTENT === null &&
				(
						(this._CURRENT_CONTENT.getPreferredCycles() === 1 && this._CURRENT_CONTENT.cycleRun / (curCycleTime === 0 ? 1 : curCycleTime) > .5)
						|| (this._CURRENT_CONTENT.hasRunCycles / this._CURRENT_CONTENT.getPreferredCycles() >= .5)
						)
				) {
			chooseNext = true;
		}

		/*
		 Let's ignore this check for now.
		 if(this._NEXT_CONTENT !== null) {
		 
		 if(topChoices.indexOf(this._NEXT_CONTENT) == -1) {
		 chooseNext =true;
		 }
		 
		 
		 }
		 */

		if (chooseNext) {
			if (topChoices.length) {
				var rand = Math.floor(Math.random() * topChoices.length);
				this._NEXT_CONTENT = topChoices[rand];
			}

		}

		// Call cycle if app has run its cycleTime
		var should_cycle = false;
		if (curCycleTime > 0 && this._CURRENT_CONTENT.cycleRun >= curCycleTime) {
			should_cycle = true;
		}

		// keep counters running
		this._CURRENT_CONTENT.running();


		//Do we have new content and has the current app run enough cycles
		if (this._NEXT_CONTENT !== null && this._CURRENT_CONTENT.hasRunCycles + (should_cycle ? 1 : 0) >= this._CURRENT_CONTENT.getPreferredCycles()) {

			console.log("[FLOW]", this._name, this.totalTime, " changing due to has run enough cycles (" + (this._CURRENT_CONTENT.hasRunCycles + (should_cycle ? 1 : 0)) + " / " + this._CURRENT_CONTENT.getPreferredCycles() + ")");
			// change to new content and return

			var hide_only_lower = hide_only;

			// if changing content then send hide_only argument as true to lower flows
			if (!this.staysOnSelf()) {
				hide_only_lower = true;
			}

			// run flow ticks before changing content on higher flow
			if (this._CURRENT_CONTENT.flow !== null) {
				this._CURRENT_CONTENT.flow.tick(hide_only_lower, true);
			}
			console.log("[FLOW] " + this._name + ' ' + this.totalTime + " changing " + this._CURRENT_CONTENT.getName() + ' ' + this._CURRENT_CONTENT.cycleRun + ' ' + curCycleTime);

			this.changeToContent(hide_only);
			return;
		} else {
			// Run ticks for flow in flow
			if (this._CURRENT_CONTENT.flow !== null) {
				this._CURRENT_CONTENT.flow.tick(false, true);
			}

			if (chooseNext) {
				if (this._NEXT_CONTENT !== this._CURRENT_CONTENT) {
					showNextContent(this._NEXT_CONTENT.getName(), this._level);
				}
			}
		}




		// ValotaHide should cycle at the end so cycle only after changing content
		if (should_cycle) {
			if (this._CURRENT_CONTENT !== null) {
				console.log('[FLOW] ' + this._name + ' ' + this.totalTime + ' Cycle called from tick()');
				this._CURRENT_CONTENT.cycle();
			} else {
				logError('Tried to call cycle but _CURRENT_CONTENT was not there');
			}
		}

	};

	this.loadContents = function (c) {
		console.log('[FLOW] ' + this.totalTime + ' load new contents');

		for (var e in c) {
			this.addContent(c[e]);
		}

		for (var e = 0; e < this._CONTENTS.length; ++e) {

			var found = false;


			for (var d in c) {
				if (c[d].uuid === this._CONTENTS[e].uuid) {
					found = true;
					break;
				}
			}

			if (!found) {
				// Not on new contents so remove from the flow
				if (this._CONTENTS[e] === this._CURRENT_CONTENT) {
					ValotaEngine.Video.stopVideosByApp(this._CURRENT_CONTENT.uuid);
					this._CURRENT_CONTENT = null;
				}
				if (this._CONTENTS[e] === this._NEXT_CONTENT) {
					this._NEXT_CONTENT = null;
				}
				this._CONTENTS.splice(e, 1);
				--e;
			}
		}

		this.refreshCurrentVolumes();

	};

	this.chooseContent = function (top) {


		var largest = null;
		for (var e in this._CONTENTS) {

			if (!this._CONTENTS[e].getApp() ||
					!this._CONTENTS[e].getApp().ready ||
					this._CONTENTS[e].currentVolume === FLOW_VOLUME_OFF ||
					this._CONTENTS[e].isOverlay) {
				continue;
			}


			if (largest === null || (largest.currentVolume < this._CONTENTS[e].currentVolume &&
					largest.currentVolume !== FLOW_VOLUME_MUST_SHOW) ||
					this._CONTENTS[e].currentVolume === FLOW_VOLUME_MUST_SHOW) {
				largest = this._CONTENTS[e];

			}

		}
		if (largest !== null) {
			this._CURRENT_CONTENT = largest;
			if (top) {
				this._CURRENT_CONTENT.logReset();

				var st = getStoryLoc(this._CURRENT_CONTENT.getApp().uuid);
				playStory(st);
			}
		}


	};

	this.reportLog = function () {
		var ret = {
			start: this.logStart,
			end: Math.round(Date.now() / 1000),
			data: []

		};

		for (var i = 0; i < this._CONTENTS.length; ++i) {
			var add = {
				name: this._CONTENTS[i].getShortName(),
				run: Math.round(this._CONTENTS[i].logRun_ms / 1000),
				uuid: this._CONTENTS[i].uuid
			};
			this._CONTENTS[i].logRun_ms = 0;
			if (this._CONTENTS[i].type === FLOW_FLOW) {
				add.contents = this._CONTENTS[i].flow.reportLog();
			}

			ret.data.push(add);
		}

		this.logStart = ret.end;

		return ret;

	};

	function iterateFlowForContentObject(flow, name) {
		var retVal = [];
		for (var i = 0; i < flow._CONTENTS.length; ++i) {
			if (flow._CONTENTS[i].type !== FLOW_FLOW) {
				retVal.push({
					"Ready": (!flow._CONTENTS[i].getApp() || !flow._CONTENTS[i].getApp().ready) ? false : true,
					"Name": name + " - " + flow._CONTENTS[i].getShortName(),
					"Has Run": flow._CONTENTS[i].totalRun,
					"Volume": flow._CONTENTS[i].volume,
					"Current Vol": flow._CONTENTS[i].currentVolume
				});
			} else {
				var entries = iterateFlowForContentObject(flow._CONTENTS[i].flow, name + ' - ' + flow._CONTENTS[i].getShortName());
				for (var c = 0; c < entries.length; c++) {
					retVal.push(entries[c]);
				}
			}
		}

		return retVal;
	}

	this.getContentObject = function () {
		var ret = [];
		for (var i = 0; i < this._CONTENTS.length; ++i) {
			if (this._CONTENTS[i].type !== FLOW_FLOW) {
				ret.push({
					"Ready": (!this._CONTENTS[i].getApp() || !this._CONTENTS[i].getApp().ready) ? false : true,
					"Name": this._CONTENTS[i].getShortName(),
					"Has Run": this._CONTENTS[i].totalRun,
					"Volume": this._CONTENTS[i].volume,
					"Current Vol": this._CONTENTS[i].currentVolume
				});
			} else {
				var entries = iterateFlowForContentObject(this._CONTENTS[i].flow, this._CONTENTS[i].getShortName());
				for (var c = 0; c < entries.length; c++) {
					ret.push(entries[c]);
				}
			}
		}
		return ret;
	};


	/*
	 this.logHour = function() {
	 
	 var tot_vol = 0;
	 var vals = [];
	 for(var i = 0;i<this._CONTENTS.length;++i) {
	 tot_vol += this._CONTENTS[i].currentVolume;
	 vals.push({
	 vol:this._CONTENTS[i].currentVolume,
	 ticks:this._CONTENTS[i].hourRun,
	 name:this._CONTENTS[i].name
	 });
	 this._CONTENTS[i].hourRun = 0;
	 }
	 var ap = [];
	 var tot_mins =this.hourTime*FLOW_SLOWNESS/60;
	 for(var el in vals) {
	 var shown  =vals[el].ticks*FLOW_SLOWNESS/60;
	 var str = "[shown:" + Math.round(shown*100)/100 + "min	" + Math.round(shown/tot_mins*10000)/100 + "%]	" +vals[el].name;
	 ap.push(str);
	 }
	 
	 console.log( _DATE.getUTCHours()+"\n"+ ap.join("\n")+ "\n");//'TOTAL:'+tot_mins + 'mins'
	 
	 this.hourTime = 0;
	 
	 }
	 
	 this.logDay = function() {
	 
	 var tot_vol = 0;
	 var vals = [];
	 for(var i = 0;i<this._CONTENTS.length;++i) {
	 tot_vol += this._CONTENTS[i].currentVolume;
	 vals.push({
	 vol:this._CONTENTS[i].currentVolume,
	 ticks:this._CONTENTS[i].dayRun,
	 name:this._CONTENTS[i].name
	 });
	 this._CONTENTS[i].dayRun = 0;
	 }
	 var ap = [];
	 var tot_mins =this.dayTime*FLOW_SLOWNESS/60;
	 for(var el in vals) {
	 var shown  =vals[el].ticks*FLOW_SLOWNESS/60;
	 var str = "[shown:" + parseInt(shown/60) + "h " + Math.floor(shown%60) + "min	" +Math.round(shown/tot_mins*10000)/100 + "%]	" + vals[el].name;
	 ap.push(str);
	 }
	 
	 console.log("\n\x1b[33m\x1b[1m" + new Date(_DATE.getTime() - 86400000).toDateString() +"\n" + ap.join("\n")+ "\n" + 'TOTAL:' + parseInt(tot_mins/60) + 'h ' + Math.floor(tot_mins%60)+ "mins\x1b[0m");
	 
	 this.dayTime = 0;
	 
	 }
	 
	 this.logTotal = function() {
	 
	 var tot_vol = 0;
	 var vals = [];
	 for(var i = 0;i<this._CONTENTS.length;++i) {
	 tot_vol += this._CONTENTS[i].currentVolume;
	 vals.push({
	 vol:this._CONTENTS[i].currentVolume,
	 ticks:this._CONTENTS[i].totalRun,
	 name:this._CONTENTS[i].name
	 });
	 this._CONTENTS[i].totalRun = 0;
	 }
	 var ap = [];
	 var days, hours;
	 var tot_mins =this.totalTime*FLOW_SLOWNESS/60;
	 for(var el in vals) {
	 var shown  =vals[el].ticks*FLOW_SLOWNESS/60;
	 days = shown/(60*24);
	 hours = shown%(60*24);
	 var str = "[shown:" + parseInt(days) + "d " + parseInt(hours/60) + "h " + Math.floor(hours%60) + "min	" + Math.round(shown/tot_mins*10000)/100 +"%]	" + vals[el].name;
	 ap.push(str);
	 }
	 days = tot_mins/(60*24);
	 hours = tot_mins%(60*24);
	 console.log("\n\n\x1b[31m\x1b[1mTOTAL FOR " + parseInt(days) + 'd ' + parseInt(hours/60) + 'h ' + Math.floor(hours%60)+ "mins\n"+ ap.join("\n")+ "\n\x1b[0m");
	 
	 this.totalTime = 0;
	 
	 }*/
};